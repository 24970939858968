<template>
<div>

  <div v-show="false">
    <template v-for="(entity, keyE) in $store.state.map.entities">
      <PopupMarkerEntity :entity="entity" :key="keyE"/>
    </template>
  </div>

  <l-map
    ref="map"  
    :options="{ scrollWheelZoom:true, zoomControl: false }"
    :zoom="zoom"  
    :maxZoom="13"
    :minZoom="4"
    :center="mapCenter"  
    id="map-container"
    style="" 
    :style="'height:'+($vuetify.breakpoint.height-menuTopHeight)+'px;'"
  >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>

      <v-marker-cluster ref="markerCluster" :options="clusterOptions"></v-marker-cluster>

      <l-marker color="red" :icon="markerMain" 
                :lat-lng="[lat, lng]"
                ref="rootMarker"
                draggable 
                @dragend="dragend"
                @drag="rootMarkerDrag" >
          <l-popup :open="true" :width="400" class="pa-4 text-center" ref="popup">
            <h2>Votre position initiale</h2><br>
            <v-divider/><br>
            <b>
                Déplacez l'icône sur la carte<br>
                pour indiquer la position qui vous convient ...
            </b>
            {{ cityAddressPopup }}
            <v-btn block color="secondary"
                  elevation="0" @click="fetchMap"
                  class="mt-4">
              <v-icon small>mdi-refresh</v-icon> Actualiser la carte
            </v-btn>
            <v-btn block color="green"
                  elevation="0" @click="saveAddress"
                  class="mt-2">
              <v-icon small>mdi-check</v-icon> Valider cette position
            </v-btn>
            <div class="text-left">
            <!-- LAT: {{ lat }}<br>LNG: {{ lng }} -->
            </div>
          </l-popup>
      </l-marker>

      <!-- MAIN CIRCLE TO SHOW ROOT RADIUS -->
      <l-circle :lat-lng="[lat, lng]" 
                :radius="rootCircle.radius"
                color="rgba(84, 97, 173, 0.5)"
                fill-color="rgba(84, 97, 173)"
                :fill-opacity=".35" ref="rootCircle">
      </l-circle>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-text-field dense outlined solo elevation="0" 
                        hide-details v-model="searchPlaceStr"
                        class="text-search-geo"
                        placeholder="Rechercher un lieu, une adresse..."
                        :loading="loadPlaces"
                        v-bind="attrs" v-on="on" 
                        @change="searchPlace()"
                        append-icon="mdi-magnify"
                        @click:append="searchPlace()"
                        style="">
          </v-text-field>
        </template>
        <ListNominatimResult :places="places" :loadPlaces="loadPlaces" :onclick="setNewCenter"/>
      </v-menu>

      <v-btn small class="d-md-none open-list-res-map" 
              fab style="z-index:1000;"
              @click="$root.$emit('openDialogResMap')">
        <v-icon small>mdi-magnify</v-icon>
      </v-btn>

      <v-btn color="secondary"
            elevation="0" @click="$root.$emit('fetchMap')"
            class="mt-4 btn-tool-map tool-map2">
        <v-icon small>mdi-refresh</v-icon> Actualiser les résultats
      </v-btn>
      <v-btn elevation="0" @click="$root.$emit('closeDialogSearchGeo')"
            class="mt-4 btn-tool-map tool-map1">
        <v-icon small>mdi-format-list-checkbox</v-icon> Afficher la liste
      </v-btn>

  </l-map>

  <v-slider v-model="rootRadius" 
            :min="1000" :max="600000" 
            @change="updateRadius"
            color="secondary"
            inverse-label class="slider-geo-block"
            :label="parseInt(rootRadius/1000)+' km'"
            :step="1000"
  >
      <template v-slot:prepend>
        <v-icon @click="decrement">mdi-minus</v-icon>
      </template>

      <template v-slot:append>
        <v-icon @click="increment">mdi-plus</v-icon>
      </template>
  </v-slider>

  <div class="selector-entity-type">
    <v-btn fab x-small @click="selectNoEntityType" 
            class="btn-selector mx-1" :color="$store.state.map.entityTypeSelected.length > 0 ? 'white' : 'black'"
            :outlined="$store.state.map.entityTypeSelected.length > 0"
            title="Afficher tout">
      <i class="fa fa-asterisk" :color="$store.state.map.entityTypeSelected.length > 0 ? 'black' : 'white'"></i>
    </v-btn>
    <v-btn fab x-small @click="selectEntityType('user')"
            class="btn-selector mx-1" color="red"
            :outlined="$store.state.map.entityTypeSelected.indexOf('user')==-1"
            title="Joueurs">
      <i class="fa fa-user"></i>
    </v-btn>
    <template v-for="(cat) in $store.state.app.data.companyCategory">
      <v-btn fab x-small :key="cat._id" @click="selectEntityType(cat._id)"
            class="btn-selector mx-1" :color="cat.iconColor"
            :outlined="$store.state.map.entityTypeSelected.indexOf(cat._id)==-1"
            :title="cat.name">
        <i :class="'fa fa-'+cat.iconFa"></i>
      </v-btn>
    </template>

  </div>

  <v-dialog v-model="showDialog"
            max-width="700" :fullscreen="$vuetify.breakpoint.width < 600"
            content-class="dialog-large-bordered" scrollable>
    <v-card style="border-radius:0px;">
      <v-toolbar elevation="1">
        <v-icon small class="mr-1">mdi-map-marker</v-icon> Définir votre position initiale
        <v-spacer/>
        <v-btn @click="showDialog = false" outlined icon small class="mr-0">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      

      <v-card-text class="mt-5">
        <h2 class="amber--text">
          Salut <span style="font-weight:300">{{ $store.state.auth.user.name }}</span>,
          et bienvenue parmis nous ! <v-icon color="red" large>mdi-party-popper</v-icon>
        </h2>
        <v-divider class="my-3"/>
        Avant de commencer à jouer, nous avons besoin de définir votre position initiale !
        <br>
        Merci donc d'indiquer sur la carte à quel endroit vous souhaitez démarrer la partie.
        <br>
        <small>Vous êtes entièrement libres de vous placer où bon vous semble.</small>
        <br><br>
        <b>Conseils :</b>
        <br>
        Pour avoir plus de chances de survie, 
        nous vous conseillons de vous localiser dans une zone déjà occupée par d'autres joueurs,
        afin de bénéficier de l'activité économique déjà présente sur ce territoire.
        <br><br>
        Mais vous pouvez aussi décider de vous placer dans une zone moins peuplée,
        et fonder votre propre "zone autonome", c'est vous qui voyez !

        <v-divider class="my-3"/>

        <b>Avertissement :</b>
        <br>
        Dans un monde en manque de pétrole, les distances sont des obstacles de plus en plus difficiles à franchir.
        Si vous décidez de vivre loin des autres joueurs, vous devrez donc vous atteler à construire votre propre économie locale,
        car les coûts de déplacement seront trop élevés pour consommer loin de chez-vous...


        <v-divider class="my-3"/>

        <b>
          - Déplacer l'icône sur la carte pour trouver l'endroit qui vous convient.<br>
          - Explorez la zone pour découvrir les différents acteurs économiques déjà localisés.<br>
          - Valider votre position et passez à l'étape suivante !
        </b>

      </v-card-text>

      <v-toolbar>
        <v-spacer/>
        <v-btn color="blue" @click="showDialog = false">
          <v-icon>mdi-map-marker</v-icon>
          Ok, je me localise !
        </v-btn>
      </v-toolbar>
    </v-card>
    
  </v-dialog>

</div>
</template>

<style>
  @import "~leaflet/dist/leaflet.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>

<script>

import axios from "axios"
import core from '../../plugins/core.js'
import router from '../../router/router'

const config = require('../../config/' + process.env.NODE_ENV)

import L from 'leaflet';
import {LMap, LTileLayer, LMarker, LCircle, LPopup} from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'

import '@/assets/js/dist/leaflet.awesome-markers.js'
import '@/assets/js/dist/fonts/font-awesome.css'
import '@/assets/js/dist/leaflet.awesome-markers.css'

import '@/assets/css/mainmap.css'

import ListNominatimResult from '@/components/offer/ListNominatimResult.vue'

import PopupMarkerEntity from '@/components/map/PopupMarkerEntity.vue'
import nominatim from '../../services/nominatim.js'


export default {
  name: 'home', 
  components: {
    LMap, LTileLayer, LMarker, LCircle, LPopup, ListNominatimResult,
    'v-marker-cluster' : Vue2LeafletMarkerCluster,
    PopupMarkerEntity
  },
  props: [''],
  data: () => ({
    search: "",
    results: 0,
    showMap: false,

    city: "",
    address: "",
    lat: 46.95026224218562, 
    lng: 2.5913274703850764,

    rootMarker: {
      lat: 46.95026224218562,
      lng: 2.5913274703850764,
    },
    rootCircle: {
      radius: 600000
    },
    rootRadius: 600000,

    markerClusterList: [],

    loadPlaces: false,
    places: null,
    cityAddressPopup: "",

    zoom:6,
    mapCenter: L.latLng(46.95026224218562, 2.5913274703850764),
    url : config.mapTilesUrl,
    attribution:'', //&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    popupContent: "ok",

    clusterOptions:  { showCoverageOnHover: false, maxClusterRadius: 30 },
    searchPlaceStr: "",

    menuTopHeight: 64,

    entityTypeSelected: [],

    showDetailsSelector: true,
    detailsSelector: "",

    showDialog: true,

  }),
  mounted: async function(){
    //if(this.$store.state.auth.user != null)
    this.init()

    this.$root.$off('invalidateSizeSearchGeoBlock').$on('invalidateSizeSearchGeoBlock', () => { 
      //console.log("on invalidateSizeSearchGeoBlock")
      this.invalidateSize()
    })

    this.$root.$off('showEntityOnMap').$on('showEntityOnMap', (entity) => { 
      this.$refs.map.mapObject.setZoom(15)

      setTimeout(() => {
        this.$refs.map.mapObject.panTo(entity.coordinates)
        setTimeout(() => {
          this.$refs.map.mapObject.invalidateSize()
          this.markerClusterList.forEach((markerItem) => {
            if(markerItem.id == entity._id)
              markerItem.marker.fire('click')
          })
        }, 300)
        
      }, 300)

    })

  },
  methods: {
    async init(){ //init est appelé par un watcher qui attend que le user soit chargé

      //si les coordonnées sont déjà définis, on ne peut pas accéder à cette vue
      // if(this.$store.state.auth.user.coordinates.length > 0) 
      //   router.push('/map')

      setTimeout(()=>{
        this.$store.dispatch('app/fetchEntities', { entityType: "companyCategory", sort: { created: 1 } })
      },100)

      if(this.$store.state.map.requestParams.coordinates != null){
        //console.log("get coordinate from requestParams")
        this.lat = this.$store.state.map.requestParams.coordinates[0]
        this.lng = this.$store.state.map.requestParams.coordinates[1]
      }

      this.mapCenter = L.latLng(this.lat, this.lng)

      this.$root.$emit("onChangeSearchGeo", { coordinates: [this.lat, this.lng], 
                                              radius: this.rootRadius, 
                                              fetch: true })

      this.showMap = true
      this.invalidateSize()

      this.fetchMap()

      this.updateMarkerMap()
    },

    async fetchMap(){
      //console.log("searchBlock fetchMap", this.type, this.amountMin, this.amountMax)
      //this.showDialogGeoSearch = false
      this.$store.dispatch('map/fetchMap', {  //userType: this.userType,
                            coordinates: [this.lat, this.lng],
                            radius: this.rootRadius,
                            fetchLimit:  500
      })
    },
    async saveAddress(){
      let params = {
        lat: this.lat,
        lng: this.lng
      }

      const { data } = await axios.post('user/set-addresse', params)
      if(!data.error){
        this.$store.dispatch('auth/refreshUserData')
        router.push('/heritage')
      }else{
        console.log("error creating offer", data)
      }
    },

    fitBoundRes: function(){
      let newBound = this.$refs.markerCluster.mapObject.getBounds()
      if(newBound._southWest != null)
        this.$refs.map.mapObject.fitBounds(newBound)
      else
        this.$refs.map.mapObject.fitBounds(this.$refs.rootCircle.mapObject.getBounds())
    },
    selectNoEntityType: function(){
      this.$store.dispatch('map/selectNoEntityType')
      this.updateMarkerMap()
      this.fitBoundRes()
    },
    selectEntityType: function(catId){
      this.$store.dispatch('map/selectEntityType', catId)
      this.updateMarkerMap()
      this.fitBoundRes()
    },
    inMapFilterType(entity){
      return this.$store.state.map.entityTypeSelected.length == 0
          || (entity.dataType == "user"    && this.$store.state.map.entityTypeSelected.indexOf('user') > -1) 
          || (entity.dataType == "company" && this.$store.state.map.entityTypeSelected.indexOf(entity.category._id) > -1)
    },
    updateMarkerMap(){
        if(this.$refs.markerCluster == null) return 

        let mks = this.$refs.markerCluster.mapObject
        if(mks != null) mks.clearLayers()
        this.markerClusterList = []

        this.cntEntityOnMap = 0
        this.$store.state.map.entities.forEach((entity) => {
          if(entity.coordinates != null && this.inMapFilterType(entity)){
            //this.cntEntityOnMap++
            //console.log("#MAIN updateMarkersEntitys uid:", entity.uid)
            let icon = this.markerIconEntity(entity)
            //if(window.isDev) { console.log("show entity on map", entity.name, icon); }
            let marker = L.marker([entity.coordinates[0], entity.coordinates[1]], { icon: icon })
            marker.on('click', () => {
              this.entityMapPopup = entity
              setTimeout(()=>{
                let popup = document.getElementById("popup-entity-"+entity._id).innerHTML
                marker.bindPopup("<div id='popup"+entity._id+"' style='min-width:300px'>" +
                                    popup +
                                "</div>")
                marker.openPopup()
                let el = document.querySelector(".leaflet-popup-content #btn-show-details-entity-" + entity._id)
                
                el.addEventListener("click", ()=>{
                  this.$root.$emit('openDialogEntity', entity)
                })
              }, 300)
              
            })
            
            mks.addLayer(marker)
            this.markerClusterList.push({ id: entity._id, marker: marker })
          }
        })
    },
    searchPlace: async function(){
      let query = this.searchPlaceStr + " FR"
      this.loadPlaces = true
      this.places = []
      this.showMap = true
      this.invalidateSize()
      nominatim.search(query, (res)=>{
        this.loadPlaces = false
        if(res.error == false){
          this.places = res.data
          this.setNewCenter(this.places[0].lat, this.places[0].lng)
        }
      })
    },
    setNewCenter: function(lat, lng){
      console.log("setNewCenter", lat, lng)
      this.$refs.map.mapObject.invalidateSize();

      lat = parseFloat(lat)
      lng = parseFloat(lng)
      this.mapCenter = [lat, lng]
      this.lat = lat
      this.lng = lng
      this.updateRadius()
      //this.zoom = 14
      
      setTimeout(() => {
        this.$refs.map.mapObject.invalidateSize()
      }, 100)
      setTimeout(() => {
        //this.$refs.marker.mapObject.openPopup()
        this.$refs.map.mapObject.invalidateSize()
      }, 500)
      
    },
    dragend(e){ 
      e.target.openPopup()
      this.lat = e.target.getLatLng().lat
      this.lng = e.target.getLatLng().lng

      //this.fetchMap()
    },
    rootMarkerDrag: function(){
      this.lat = this.$refs.rootMarker.mapObject._latlng.lat
      this.lng = this.$refs.rootMarker.mapObject._latlng.lng
    },
    invalidateSize(){ 
        setTimeout(() => { 
          this.$refs.map.mapObject.invalidateSize()
          this.$refs.rootMarker.mapObject.openPopup()
        }, 400) 
    },

    decrement: function(){
      if(this.rootRadius <= 1000) return
      this.rootRadius -= 1000
      this.updateRadius(false)
    },
    increment: function(){
      this.rootRadius += 1000
      this.updateRadius(false)
    },
    updateRadius: async function(loadContent=true){
      console.log("updateRadius", loadContent)
      this.rootCircle.radius = this.rootRadius
      var tCircle = L.circle(L.latLng(this.lat, this.lng), { radius: this.rootCircle.radius })
      tCircle.addTo(this.$refs.map.mapObject)
      this.$refs.map.mapObject.fitBounds(tCircle.getBounds())
      tCircle.remove()
      this.$refs.rootMarker.mapObject.openPopup()
      this.$root.$emit("onChangeSearchGeo", { coordinates: [this.lat, this.lng], radius: this.rootRadius })
    },
    monyToEuro(amount){ return amount * config.monyToEuro },
    nl2br: function(str, is_xhtml){
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
      let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
      let maxLength = 40
      let p = newStr.length > maxLength ? '...' : ''
      newStr = newStr.substr(0, maxLength) + p
      return newStr
    },
    showDialogDetails(res) { 
      this.$root.$emit("openDialogDetails", res)
    },
    baseUrl(){ return core.baseUrl() },
    markerIconEntity: function(entity){
        //let avatar = core.avatarUrl(page.uid) //'markers/icon-popup.png'
        let color = "blue"
        let icon = "point"

        if(entity.dataType != null){
          color = this.$store.state.map.dataTypeConfigDefault[entity.dataType].color
          icon = this.$store.state.map.dataTypeConfigDefault[entity.dataType].icon
        }
        if(entity.dataType == "company"){
          color = entity.category.iconFaColor
          icon = entity.category.iconFa
        }

        return L.AwesomeMarkers.icon({
                  icon: icon,
                  prefix: 'fa',
                  markerColor: color
              })
    },
    calcTopBarHeight(){
      let el = document.getElementById('top-bar')
      this.menuTopHeight = el ? el.offsetHeight : 64
    },
  },
  computed: {
    markerMain(){ 
      //let color = "blue"
      return L.AwesomeMarkers.icon({
                  icon: 'circle',
                  prefix: 'fa',
                  extraClass: '2x',
                  markerColor: 'black'
              })
    },
    marker(){ 
      let color = "orange"
      return L.icon({
        iconUrl: '/marker/marker-'+color+'.png',
        shadowUrl: '/marker/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
    },
    
  },
  
  watch: {
    
      '$store.state.map.requestParams.coordinates': { immediate: true, 
          async handler () {
            // if(this.$store.state.map.requestParams.coordinates == null) return
            // let lat = this.$store.state.map.requestParams.coordinates[0]
            // let lng = this.$store.state.map.requestParams.coordinates[1]
            // this.setNewCenter(lat, lng)
          }
      },
      '$store.state.auth.user._id': { immediate: true, 
          async handler () {
              
            if(this.$store.state.auth.isLogin){
              //console.log("user logged !", this.$store.state.auth.user)
              this.init()
            }
          }
      },
      '$store.state.map.entities': { immediate: true, 
          async handler () {
              this.updateMarkerMap()
          }
      },
      '$vuetify.breakpoint.width': { immediate: true, 
          async handler () {
              this.calcTopBarHeight()
          }
      },
      '$vuetify.breakpoint.height': { immediate: true, 
          async handler () {
              this.calcTopBarHeight()
          }
      },
  }, 
}
</script>